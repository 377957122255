<template>
  <div>
    <van-nav-bar
      title="文章详情"
      @click-left="goBack"
      left-text="返回"
      :left-arrow="true"
      style="position: fixed; top: 0; right: 0; left: 0;"
      right-text="翻译"
      @click-right="fanyi"
    />
    <!-- <div v-html="data.content" style="    margin-top: 3rem; padding: 5px;"></div> -->
    <div style="    margin-top: 3.5rem; padding: 5px;">
      <div>{{data.title}}</div>
      <van-divider content-position="center"></van-divider>
      <div v-html="data.content"></div>
    </div>
    <!-- <van-panel :title="data.title" style="    margin-top: 3.5rem; padding: 5px;">
      <div v-html='data.content'></div>
    </van-panel>-->
    <van-popup
      v-model="show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div style="    margin-top: 3.5rem; padding: 5px;">
        <div>
          {{ru.title}}
          <van-button
            plain
            type="info"
            size="mini"
            v-clipboard:copy="ru.title"
            v-clipboard:success="onCopy"
          >复制</van-button>
        </div>
        <van-divider content-position="center"></van-divider>
        <div v-html="ru.content" style='margin-bottom:20px'></div>

        <van-button block type="info" v-clipboard:copy="ru.content" v-clipboard:success="onCopy">复制</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Popup, Notify,Toast } from "vant";
import md5 from "md5";
import jsonp from "jsonp";
export default {
  data() {
    return {
      data: {
        title: "",
        content: ""
      },
      ru: {
        title: "",
        content: ""
      },
      show: false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onCopy(e) {
      Notify({ type: "success", message: "复制成功" });
    },
    baiDuFanyi(value, type) {
      var appid = "20190728000321988";
      var key = "bYdMGbhW5hrasEgvX7OL";
      var salt = new Date().getTime();
      var query = value;
      // 多个query可以用\n连接  如 query='apple\norange\nbanana\npear'
      var from = "cn";
      var to = "ru";
      var str1 = appid + query + salt + key;
      var sign = md5(str1);
      let postData = this.$qs.stringify({
        q: query,
        appid: appid,
        salt: salt,
        from: from,
        to: to,
        sign: sign
      });
      var str = "";
      jsonp(
        "https://api.fanyi.baidu.com/api/trans/vip/translate?" + postData,
        null,
        (err, data) => {
          if (err) {
            console.error(err.message);
          } else {
            // this.fanyi_content = "";
            if (data.trans_result.length > 0) {
              let str = "";
              data.trans_result.forEach(item => {
                str += item.dst + "\r\n";
              });
              if (type == 1) {
                this.ru.title = str;
              } else {
                this.ru.content = str;
              }
            }
          }
        }
      );
      return str;
    },
    fanyi() {
      Toast.loading({
        message: '翻译中...',
        forbidClick: true
      });
      this.baiDuFanyi(this.data.title, 1);
      setTimeout(()=>{
        this.baiDuFanyi(this.deleteHtmlTag(this.data.content), 2);
        this.show = true;
      },2000);
      
      // console.log(title)
    },
    deleteHtmlTag(str) {
      str = str.replace(/<[^>]+>|&[^>]+;/g, "").trim(); //去掉所有的html标签和&nbsp;之类的特殊符合
      return str;
    },
    goBack() {
      this.$router.go(-1); //返回上一层
    },
    fetchData(page) {
      // console.log(page)

      let postData = this.$qs.stringify({
        page: page,
        id: this.$route.query.id
      });
      this.$axios
        .post(this.$baseUrl + "/api/article/content", postData)
        .then(response => {
          this.data = response.data.data;
        });
    }
  }
};
</script>
<style socped>
</style>