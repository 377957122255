<template>
  <div>
    <van-nav-bar
      title="俄文翻译"
      @click-left="goBack"
      left-text="返回"
      :left-arrow="true"
      style="position: fixed; top: 0; right: 0; left: 0;"
    />
    <div style="margin-top:2rem;    padding: 17px;"></div>

    <van-cell-group>
      <van-field
        v-model="content"
        rows="6"
        autosize
        label="原文"
        type="textarea"
        maxlength="50"
        placeholder="请输入原文"
        show-word-limit
      />
    </van-cell-group>
    <section class="van-doc-demo-section demo-button">
     <div style='margin:10px;text-align: center;'>

         <div class="demo-button-row">
        <van-button size="small">{{fromData.text}}</van-button>

        <van-button plain hairline round size="mini" icon='exchange' @click="qiehuan" style='margin-right: 16px;'></van-button>
       
        <van-button size="small" >{{toData.text}}</van-button>
    
        <van-button type="info" size="small" @click="baiDuFanyi(content)">翻译</van-button>
        </div>
    </div> 
    </section>
    <van-cell-group>
      <van-field
        v-model="fanyi_content"
        rows="6"
        autosize
        label="译文"
        type="textarea"
        maxlength="50"
        placeholder="翻译结果"
        show-word-limit
      />
    </van-cell-group>

   
   
  </div>
</template>

<script>
import md5 from "md5";
import jsonp from "jsonp";
export default {
  data() {
    return {
      type: 1,
      content: "",
      fanyi_content: "",
      fromData: {
        text: "中文",
        type: "zh"
      },
      toData: {
        text: "俄文",
        type: "ru"
      }
    };
  },
  watch: {
    type(val) {
      if (val == 1) {
        (this.fromData = {
          text: "中文",
          type: "zh"
        }),
          (this.toData = {
            text: "俄文",
            type: "ru"
          });
      } else {
        (this.fromData = {
          text: "俄文",
          type: "ru"
        }),
          (this.toData = {
            text: "中文",
            type: "zh"
          });
      }
    }
  },
  created() {},
  methods: {
    goBack() {
      this.$router.go(-1); //返回上一层
    },
    qiehuan() {
      this.type = this.type == 1 ? 2 : 1;
    },
    baiDuFanyi(value) {
      if (value == "") {
        this.$message.error("请输入正确的原文");
        return;
      }
      var appid = "20190728000321988";
      var key = "bYdMGbhW5hrasEgvX7OL";
      var salt = new Date().getTime();
      var query = value;
      // 多个query可以用\n连接  如 query='apple\norange\nbanana\npear'
      var from = this.fromData.type;
      var to = this.toData.type;
      var str1 = appid + query + salt + key;
      var sign = md5(str1);
      let postData = this.$qs.stringify({
        q: query,
        appid: appid,
        salt: salt,
        from: from,
        to: to,
        sign: sign
      });

      jsonp(
        "https://api.fanyi.baidu.com/api/trans/vip/translate?" + postData,
        null,
        (err, data) => {
          if (err) {
            console.error(err.message);
          } else {
            this.fanyi_content = "";
            if (data.trans_result.length > 0) {
              data.trans_result.forEach(item => {
                this.fanyi_content += item.dst + "\r\n";
              });
            }
          }
        }
      );
    }
  }
};
</script>
<style>
.demo-button .van-button--large {
  margin-bottom: 16px;
}
.demo-button .van-button--small,
.demo-button .van-button--normal:not(:last-child) {
  margin-right: 16px;
}
.demo-button .van-doc-demo-block {
  padding: 0 16px;
}
.demo-button .van-doc-demo-block__title {
  padding-left: 0;
}
.demo-button-row {
  margin-bottom: 12px;
}
</style>
