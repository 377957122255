<template>
  <div>
    <van-nav-bar
      title="移动会议室"
      style="position: fixed; top: 0; right: 0; left: 0; "
      right-text="进入会议室"
      @click-right="goRoom"
    />
    <div style='margin-top:2rem;    padding: 17px;'>
       <!-- <van-button type="info" size="large" style=''  @click='goRoom'>进入会议室</van-button> -->
    </div>

    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getData">
      <van-cell
        v-for="(item,key) in list"
        :key="key"
        :title="'房间号：'+item.code"
        :value="'密码：'+item.pwd"
        size="large"
        :label="item.uuid"
      />
    </van-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1
    };
  },
  created() {},
  methods: {
    goRoom() {
      // localtion.href="intent://#Intent;action=android.intent.action.VIEW;scheme=googlechrome://navigate?url=https://www.baidu.com/?a=n&b=c;package=com.android.chrome;end"
      window.open("https://meeting-room.zhoupengyu.cn");
    },
    getData(cid = 0, category = "全部资讯") {
      let postData = this.$qs.stringify({
        page: this.page
      });
      this.$axios
        .post(this.$baseUrl + "/api/meeting/list", postData)
        .then(response => {
          console.log(response);
          let list = response.data.data.data;
          list.forEach(item => {
            this.list.push(item);
          });

          this.loading = false;
          this.page++;
          this.show = false;
          //  this.finished = true;
          // 数据全部加载完成
          if (this.page >= response.data.data.last_page) {
            this.finished = true;
          }
        });
    }
  }
};
</script>
<style scoped>
.van-nav-bar__title {
  max-width: 30% !important;
  margin: 0 !important;
}
</style>
