<template>
  <div>
    <van-nav-bar
      title="最新资讯"
      right-text="分类"
      @click-right="show ? (show = false) : (show = true)"
      style="position: fixed; top: 0; right: 0; left: 0"
    >
      <!-- <div slot="right">
        <van-icon name="apps-o" style="font-size: 1.6rem;" />
      </div>-->
    </van-nav-bar>

    <van-search
      placeholder="请输入搜索关键词"
      v-model="value"
      style="margin-top: 2.6rem"
    />
    <van-divider content-position="center">{{ category }}</van-divider>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getData(cid)"
    >
      <van-cell v-for="(item, key) in list" :key="key" :title="item.title">
        <!-- <el-button v-if="scope.row.file" type="text" @click="open(scope.row.file)">打开</el-button> -->
        <van-button
          slot="right-icon"
          type="primary"
          v-if="item.file"
          size="mini"
          @click="open(item.file)"
          >下载</van-button
        >
        <van-button
          slot="right-icon"
          type="info"
          v-else
          size="mini"
          @click="$router.push('/articleDetail?id=' + item.id)"
          >查看</van-button
        >
      </van-cell>
    </van-list>
    <van-popup
      v-model="show"
      position="right"
      :style="{ width: '50%', height: '100%' }"
    >
      <van-cell title="全部资讯" @click="getData(0)" />

      <van-cell
        :title="item.label"
        :name="key"
        v-for="(item, key) in options"
        :key="key"
        @click="getData(item.value)"
      />
      <!-- <van-collapse v-model="activeName" accordion>
        <van-collapse-item :title="item.label" :name="key" v-for="(item,key) in options" :key="key">
          <van-cell
            v-for="(val,index) in item.children"
            :key="index"
            :title="val.label"
            @click="getData(val.value,val.label)"
          />
        </van-collapse-item>
      </van-collapse> -->
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      activeKey: "",
      value: "",
      activeName: "",
      // options: [
      //   {
      //     value: "10",
      //     label: "商机查询",
      //     children: [
      //       {
      //         value: "1",
      //         label: "供需大全"
      //       },
      //       {
      //         value: "2",
      //         label: "商情发布"
      //       },
      //       {
      //         value: "3",
      //         label: "展会展览"
      //       }
      //     ]
      //   },
      //   {
      //     value: "11",
      //     label: "国情商情",
      //     children: [
      //       {
      //         value: "4",
      //         label: "国情概述"
      //       },
      //       {
      //         value: "5",
      //         label: "国别环境"
      //       },
      //       {
      //         value: "6",
      //         label: "区域经济"
      //       }
      //     ]
      //   },
      //   {
      //     value: "12",
      //     label: "政策法规",
      //     children: [
      //       {
      //         value: "7",
      //         label: "企业法"
      //       },
      //       {
      //         value: "8",
      //         label: "投资法"
      //       },
      //       {
      //         value: "9",
      //         label: "土地法"
      //       }
      //     ]
      //   },
      //   {
      //     value: "12",
      //     label: "合同",
      //     children: [
      //       {
      //         value: "11",
      //         label: "合同"
      //       }
      //     ]
      //   }
      // ],
      options: [
        {
          value: "11",
          label: "基本商情",
        },
        {
          value: "12",
          label: "国别概况",
        },
        {
          value: "13",
          label: "政策法规",
        },
        {
          value: "14",
          label: "企业主体",
        },
        {
          value: "15",
          label: "商会协会",
        },
        {
          value: "16",
          label: "优势行业",
        },
        {
          value: "17",
          label: "区域经贸",
        },
        {
          value: "18",
          label: "专题跟踪",
        },
        {
          value: "19",
          label: "科技专利",
        },
        {
          value: "210",
          label: "商务信息",
        },
      ],
      form: {
        cover: "",
      },
      list: [],
      loading: false,
      finished: false,
      page: 1,
      cid: 0,
      category: "全部资讯",
    };
  },
  created() {
    console.log(this.$route.query.cid);
    if (this.$route.query.cid) {
      this.cid = this.$route.query.cid;
    }
    // this.get
  },
  methods: {
    open(url) {
      window.open(url);
    },
    getData(cid = 0, category = "全部资讯") {
      // console.log(page)
      // this.listLoading = true;

      if (cid != this.cid) {
        this.list = [];
        this.cid = cid;
        this.page = 1;
        this.value = "";
      }
      this.category = category;
      let postData = this.$qs.stringify({
        cid: cid,
        page: this.page,
        keyword: this.value,
      });
      this.$axios
        .post(this.$baseUrl + "/api/article/list", postData)
        .then((response) => {
          console.log(response);
          let list = response.data.data.data;
          list.forEach((item) => {
            this.list.push(item);
          });

          this.loading = false;
          this.page++;
          this.show = false;
          //  this.finished = true;
          // 数据全部加载完成
          if (response.data.data.current_page >= response.data.data.last_page) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        });
    },
  },
};
</script>
<style scoped>
.van-nav-bar__title {
  max-width: 30% !important;
  margin: 0 !important;
}
</style>