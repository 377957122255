<template>
  <div class="login-container">
    <div class="login-form">
      <div class="title-container">
        <h3 class="title">登录</h3>
      </div>

      <div style="margin-top:3rem">
        <van-cell-group>
          <van-field
            v-model="loginForm.username"
            required
            clearable
            label="用户名"
            placeholder="请输入用户名"
          />

          <van-field
            v-model="loginForm.password"
            type="password"
            label="密码"
            placeholder="请输入密码"
            required
          />
        </van-cell-group>
      </div>
      <div style="margin-top:3rem;margin-top:15px">
        <van-button type="info" size="large" @click="handleLogin">登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog,Toast } from "vant";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: ""
      },
    };
  },
  methods: {
    
    handleLogin() {
      if(this.loginForm.username == ''){
         Toast.fail('账号不正确');
         return false;
      }
      if(this.loginForm.password == ''){
         Toast.fail('密码不正确');
         return false;
      }
      let formData = this.$qs.stringify(this.loginForm);
      this.$axios
        .post(this.$baseUrl + "api/login/checkLogin", formData)
        .then(response => {
         
          if (response.data.data) {
            localStorage.setItem('user',JSON.stringify(response.data.data))
            this.$router.push("/");
          } else {
            Toast.fail('密码不正确');
            // this.$message.error("用户名或密码不正确");
          }
        });
    }
  }
};
</script>
<style>
html {
  height: 100%;
}
body {
  height: 100%;
}
</style>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;

    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>