<template>
  <div>
    <div
      class="user-poster"
      :style="{backgroundImage:'url('+require('../assets/background.jpg')+')'}"
    >
      <div style="text-align: center;padding-top: 3rem;">
        <!-- <van-row>
          <van-col span="8">span: 8</van-col>
          <van-col span="8">span: 8</van-col>
          <van-col span="8">span: 8</van-col>
        </van-row>-->
        <van-image
          round
          width="5rem"
          height="5rem"
          fit="cover"
          :src="user.avatar?$baseUrl+'/uploads/'+user.avatar:'https://img.yzcdn.cn/vant/cat.jpeg'"
        />
        <div style="margin-top:10px">{{user.nickname}}</div>
      </div>
    </div>

    <van-grid :square="false" :column-num="2" :border="true" :gutter="10" style="margin: 10px;">
      <van-grid-item style="color:#ee0a24" icon="fire-o" text="商机查询" to="/article?cid=2" />
      <van-grid-item style="color:#1989fa" icon="aim" text="国情商情" to="/article?cid=4" />
      <van-grid-item style="color:#07c160" icon="completed" text="政策法规" to="/article?cid=7" />
      <van-grid-item style="color:#ff8917" icon="todo-list-o" text="合同范本" to="/article?cid=11" />
    </van-grid>
    <!-- <img src="../assets/background.jpg" /> -->
    <!-- <van-row class="user-links">
      <van-col span="6">
        <van-icon name="pending-payment" />待付款
      </van-col>
      <van-col span="6">
        <van-icon name="records" />待接单
      </van-col>
      <van-col span="6">
        <van-icon name="tosend" />待发货
      </van-col>
      <van-col span="6">
        <van-icon name="logistics" />已发货
      </van-col>
    </van-row>-->

    <!-- <van-cell-group class="user-group">
      <van-cell icon="records" title="全部订单" is-link />
    </van-cell-group>-->

    <van-cell-group>
      <!-- <van-cell icon="edit" title="修改资料" is-link /> -->
      <van-cell icon="info-o" title="关于我们" is-link @click="about" />
      <van-cell icon="bulb-o" title="退出登录" is-link @click="loginOut" />
    </van-cell-group>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      user: {}
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log(this.user);
  },
  methods: {
    about() {
      Dialog.alert({
        title: "关于我们",
        message: "中俄移动谈判室V1.0"
      }).then(() => {
        // on close
      });
    },
    loginOut() {
      Dialog.confirm({
        title: "确认退出登录么？"
        // message: "弹窗内容"
      })
        .then(() => {
          localStorage.removeItem("user");
          this.$router.push("/login");
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
    background-repeat: round;
  }
  &-group {
    margin-bottom: 15px;
  }
  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>